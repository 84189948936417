<template>
    <div class="wrap">
        <CancelModal v-if="cancelStatus" />
        <popup-modal v-if="isPopUpModal" :text="popupModalText" @close-modal="onCloseModal" />
        <CancelCheckModal
            v-if="cancelCheckStatus"
            :isRegistCard="isRegistCard"
            :spaceId="spaceId"
            :spaceType="'Store'"
            :spaceInfo="spaceInfo"
            @closeCancel="cancelCheckStatus = false"
            @showCancel="cancelStatus = true"
            @deletspace="onDeleteSpace"
        />
        <searchStore
            v-if="isMap"
            @closeMap="isMap = false"
            @closeMap2="closeMap()"
            @getSpaceInfo="setSpaceInfo"
            :spaceName="spaceName"
        />
        <Franchise
            v-if="franchiseStatus"
            @closeFranchise="closeFranchise()"
            :searchBrandNm="searchBrandNm"
            @insertBrand="insertBrand"
        />
        <CheckSpaceNm
            v-if="checkSpaceNm"
            :disabledInsert="disabledInsert"
            :spaceInfo="spaceInfo"
            @closeModal="closeModal()"
            @closeMap="spaceId == null ? insertSpace() : updateSpaceInfo()"
        />
        <!--######## PC ########-->
        <div class="space_pc ov_auto pb_100px">
            <section class="main myspace w_940">
                <div class="main_box fade-up">
                    <div class="main_box_title">
                        <h1>스페이스 등록</h1>
                        <div @click="linkMySpace()">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>
                    <div class="cs_main">
                        <div class="myspace_content_form">
                            <div class="form_text">
                                <h3>매장정보</h3>
                            </div>
                            <div class="pagnation">
                                <span></span>
                                <span style="width: 12%"></span>
                            </div>
                            <div>
                                <ul class="form_info_wrap box">
                                    <li class="form_info_label">
                                        <label class="form_info_label">매장명 <span class="form_info_required">*</span></label>
                                        <div class="space_input_store_box_wrap">
                                            <input
                                                id="search"
                                                type="text"
                                                class="space_input_box space_input_store_box"
                                                v-model="spaceInfo.spaceNm"
                                                @keyup.enter="inputStatus === 'map' ? searchAddrVal('map') : () => {}"
                                                :placeholder="inputStatus === 'map' ? '매장명을 검색해주세요.' : '매장명을 직접 입력해주세요.'"
                                            />
                                            <div class="space_insert_store_btn_box">
                                                <button
                                                    id="store_search"
                                                    class="space_insert_store_btn"
                                                    @click.prevent="searchAddrVal('map')"
                                                    :class="{ normal: inputStatus !== 'map', on: inputStatus === 'map' }"
                                                >
                                                    매장검색
                                                </button>
                                                <button
                                                    id="directly_search"
                                                    class="space_insert_store_btn"
                                                    @click.prevent="searchAddrVal('search')"
                                                    :class="{ normal: inputStatus !== 'search', on: inputStatus === 'search' }"
                                                >
                                                    직접입력
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="form_info_wrap box">
                                    <li class="form_info_label">
                                        <label class="form_info_label">매장주소 <span class="form_info_required">*</span></label>
                                    </li>
                                    <li class="form_info_data vertical">
                                        <div class="space_input_store_box_wrap">
                                            <input
                                                type="text"
                                                class="space_input_box has_child"
                                                v-model="spaceInfo.spaceAddr"
                                                :placeholder="
                                                    inputStatus === 'map' ? '매장명을 선택하면 자동으로 기입됩니다.' : '매장 주소를 검색해주세요.'
                                                "
                                                disabled
                                            />
                                            <input
                                                type="text"
                                                id="addressDetail"
                                                class="space_input_box child last"
                                                v-model="spaceInfo.spaceAddrDetail"
                                                placeholder="상세주소를 입력해주세요."
                                            />
                                            <div id="search-addr" class="space_insert_store_btn_box" v-if="inputStatus === 'search'">
                                                <button id="search-addr_btn" class="space_insert_store_btn on" @click.prevent="searchAddress()">
                                                    주소검색
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="form_info_wrap box">
                                    <li class="form_info_label">
                                        <label class="form_info_label">소속 브랜드 </label>
                                    </li>
                                    <li class="form_info_data vertical">
                                        <div class="space_input_store_box_wrap">
                                            <input
                                                type="text"
                                                class="space_input_box last"
                                                v-model="searchBrandNm"
                                                placeholder="브랜드명을 검색해주세요."
                                                @keyup.enter="searchBrand"
                                            />
                                            <img class="brand_icon" src="/media/img/search_icon.svg" alt="브랜드 검색" @click="searchBrand" />
                                        </div>
                                    </li>
                                </ul>

                                <ul class="form_info_wrap box">
                                    <li class="form_info_label">
                                        <label class="form_info_label">업종 <span class="form_info_required">*</span></label>
                                    </li>
                                    <li class="form_info_data">
                                        <div
                                            class="space_select_box"
                                            :class="{ on: isSectorOpen, up: isSectorOpen }"
                                            @click="isSectorOpen = !isSectorOpen"
                                            v-click-outside="onSectorOutsideClick"
                                        >
                                            {{ !spaceInfo.sector ? '대분류' : spaceInfo.sector }}
                                            <ul class="space_select-box-dropDown" :class="{ on: isSectorOpen }">
                                                <li
                                                    class="space_select_box_item"
                                                    v-for="item in sectors"
                                                    :key="item.setorId"
                                                    @click="setSector(item)"
                                                >
                                                    <span class="car_brand_name">{{ item.sectorNm }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div
                                            class="space_select_box"
                                            :class="{ on: isSectorDetailOpen, up: isSectorDetailOpen }"
                                            @click="isSectorDetailOpen = !isSectorDetailOpen"
                                            v-click-outside="onSectorDetailOutSideClick"
                                        >
                                            {{ !spaceInfo.sectorDetail ? '소분류' : spaceInfo.sectorDetail }}
                                            <ul class="space_select-box-dropDown" :class="{ on: isSectorDetailOpen }">
                                                <li
                                                    class="space_select_box_item"
                                                    v-for="item in sectorDetails"
                                                    :key="item.sectorDetailId"
                                                    @click="setSectorDetailVal(item)"
                                                >
                                                    <span class="car_brand_name">{{ item.sectorDetailNm }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <footer class="space_insert_store_bottom_box">
                                <button id="btn_submit" class="insert_page_btn next" :disabled="btnDisabled" @click="submitSpace">
                                    다음
                                </button>
                            </footer>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!--######## 모바일 ########-->
        <div class="space_mo hauto">
            <div class="mo_space_header">
                <div class="mo_header_box">
                    <a>
                        <img src="/media/images/icon_back_24.svg" @click="clickPre()" />
                    </a>
                    <h2>스페이스 등록</h2>
                    <div class="btn_close" @click="linkMySpace()">
                        <img src="/media/images/icon_close_24.svg" />
                    </div>
                </div>
                <div class="pagnation">
                    <span></span>
                    <span style="width: 12%"></span>
                </div>
            </div>
            <section class="main myspace">
                <div class="main_box">
                    <div class="cs_main">
                        <div class="myspace_content_form bt_pd">
                            <div>
                                <ul class="form_info_wrap box">
                                    <li class="form_info_label">
                                        <label class="form_info_label">매장명 <span class="form_info_required">*</span></label>
                                        <div class="space_input_store_box_wrap">
                                            <input
                                                type="text"
                                                class="space_input_box space_input_store_box"
                                                v-model="spaceInfo.spaceNm"
                                                :placeholder="inputStatus === 'map' ? '매장명을 검색해주세요.' : '매장명을 직접 입력해주세요.'"
                                            />
                                            <div class="space_insert_store_btn_box">
                                                <button
                                                    class="space_insert_store_btn"
                                                    @click.prevent="searchAddrVal('map')"
                                                    :class="{ normal: inputStatus !== 'map', on: inputStatus === 'map' }"
                                                >
                                                    매장검색
                                                </button>
                                                <button
                                                    class="space_insert_store_btn"
                                                    @click.prevent="searchAddrVal('search')"
                                                    :class="{ normal: inputStatus !== 'search', on: inputStatus === 'search' }"
                                                >
                                                    직접입력
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="form_info_wrap box">
                                    <li class="form_info_label">
                                        <label class="form_info_label">매장주소 <span class="form_info_required">*</span></label>
                                    </li>
                                    <li class="form_info_data vertical">
                                        <div class="space_input_store_box_wrap">
                                            <input
                                                type="text"
                                                class="space_input_box has_child"
                                                v-model="spaceInfo.spaceAddr"
                                                :placeholder="
                                                    inputStatus === 'map' ? '매장명을 선택하면 자동으로 기입됩니다.' : '매장 주소를 검색해주세요.'
                                                "
                                                disabled
                                            />
                                            <input
                                                type="text"
                                                id="addressDetail"
                                                class="space_input_box child last"
                                                v-model="spaceInfo.spaceAddrDetail"
                                                placeholder="상세주소를 입력해주세요."
                                            />
                                            <div class="space_insert_store_btn_box" v-if="inputStatus === 'search'">
                                                <button class="space_insert_store_btn on" @click.prevent="searchAddress()">주소검색</button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="form_info_wrap box">
                                    <li class="form_info_label">
                                        <label class="form_info_label">소속 브랜드 </label>
                                    </li>
                                    <li class="form_info_data vertical">
                                        <div class="space_input_store_box_wrap">
                                            <input
                                                type="text"
                                                class="space_input_box last"
                                                v-model="searchBrandNm"
                                                placeholder="브랜드명을 검색해주세요."
                                                @keyup.enter="searchBrand"
                                            />
                                            <img class="brand_icon" src="/media/img/search_icon.svg" alt="브랜드 검색" @click="searchBrand" />
                                        </div>
                                    </li>
                                </ul>
                                <ul class="form_info_wrap box">
                                    <li class="form_info_label">
                                        <label class="form_info_label">업종 <span class="form_info_required">*</span></label>
                                    </li>
                                    <li class="form_info_data">
                                        <div
                                            class="space_select_box"
                                            :class="{ on: isMoSectorOpen, up: isMoSectorOpen }"
                                            @click="isMoSectorOpen = !isMoSectorOpen"
                                        >
                                            {{ spaceInfo.sector === null ? '대분류' : spaceInfo.sector }}
                                        </div>
                                        <div
                                            class="space_select_box"
                                            :class="{ on: isMoSectorDetailOpen, up: isMoSectorDetailOpen }"
                                            @click="isMoSectorDetailOpen = !isMoSectorDetailOpen"
                                        >
                                            {{ spaceInfo.sectorDetail === null ? '소분류' : spaceInfo.sectorDetail }}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="mo_space_down_box1" :class="{ active: isMoSectorOpen }">
                                <ul class="mo_space_down_box_header">
                                    <li>
                                        <h2 class="mo_space_down_box1_title">대분류</h2>
                                    </li>
                                    <li @click="isMoSectorOpen = false">
                                        <svg
                                            data-v-32f9162e=""
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M12.0007 10.5862L16.9507 5.63623L18.3647 7.05023L13.4147 12.0002L18.3647 16.9502L16.9507 18.3642L12.0007 13.4142L7.05072 18.3642L5.63672 16.9502L10.5867 12.0002L5.63672 7.05023L7.05072 5.63623L12.0007 10.5862Z"
                                                fill="white"
                                            ></path>
                                        </svg>
                                    </li>
                                </ul>
                                <ul class="mo_space_box_list">
                                    <li class="mo_space_box_item" v-for="item in sectors" :key="item.sectorId" @click="setSector(item)">
                                        <span class="mo_space_box_text_name">{{ item.sectorNm }}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="mo_space_down_box1" :class="{ active: isMoSectorDetailOpen }">
                                <ul class="mo_space_down_box_header">
                                    <li>
                                        <h2 class="mo_space_down_box1_title">소분류</h2>
                                    </li>
                                    <li @click="isMoSectorDetailOpen = false">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12.0007 10.5862L16.9507 5.63623L18.3647 7.05023L13.4147 12.0002L18.3647 16.9502L16.9507 18.3642L12.0007 13.4142L7.05072 18.3642L5.63672 16.9502L10.5867 12.0002L5.63672 7.05023L7.05072 5.63623L12.0007 10.5862Z"
                                                fill="white"
                                            ></path>
                                        </svg>
                                    </li>
                                </ul>
                                <ul class="mo_space_box_list">
                                    <li
                                        class="mo_space_box_item"
                                        v-for="item in sectorDetails"
                                        :key="item.sectorDetailId"
                                        @click="setSectorDetailVal(item)"
                                    >
                                        <span class="mo_space_box_text_name">{{ item.sectorDetailNm }}</span>
                                    </li>
                                </ul>
                            </div>
                            <footer class="insert_bottom">
                                <ul class="insert_bottom_box">
                                    <button :disabled="btnDisabled" class="insert_page_btn next" @click="submitSpace">다음</button>
                                </ul>
                            </footer>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import CancelModal from '@/components/modal/Space/CancelModal.vue';
import CancelCheckModal from '@/components/modal/Space/CancelCheckModal2.vue';
import Franchise from '@/components/modal/Space/Franchise.vue';
import searchStore from '@/components/map/searchStore.vue';
import CheckSpaceNm from '@/components/modal/Map/checkSpaceNm.vue';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import dayjs from 'dayjs';
import router from '@/router';
import PopUpModal from '@/components/modal/common/PopUpModal.vue';
import vClickOutside from 'v-click-outside';
export default defineComponent({
  data () {
    return {
      isSectorOpen: false,
      isSectorDetailOpen: false,
      isMoSectorOpen: false,
      isMoSectorDetailOpen: false,
      spaceLatitude: '',
      spaceLongitude: '',
      isMap: false,
      sectors: [],
      sectorDetails: [],
      spaceInfo: {
        spaceNm: '',
        spaceAddr: '',
        sectorDetail: null,
        sector: null
      },
      spaceName: '',
      inputStatus: 'map',
      startStatus: false,
      endStatus: false,
      startStatusM: false,
      endStatusM: false,
      userInfo: {
        startTime: '09:00',
        endTime: '18:00'
      },
      cancelStatus: false,
      cancelCheckStatus: false,
      detailInfo: {},
      franchiseStatus: false,
      searchBrandNm: '',
      prevRoute: null,
      checkSpaceNm: false,
      disabledInsert: false,
      isRegistCard: true,
      isPopUpModal: false,
      curationInfo: {},
      popupModalText: '',
      spaceId: null
    };
  },
  components: {
    searchStore,
    CancelModal,
    CancelCheckModal,
    Franchise,
    CheckSpaceNm,
    'popup-modal': PopUpModal
  },
  created () {
    if (Object.keys(this.$route.params).length > 0) {
      const { spaceId } = this.$route.params;
      this.spaceId = spaceId;
    } else {
      this.spaceId = localStorage.getItem('spaceId');
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    submitSpace () {
      if (this.inputStatus === 'search') {
        this.spaceId ? this.updateCheckSpaceNm() : this.insertCheckSpaceNm();
        return false;
      } else {
        this.spaceId ? this.updateSpaceInfo() : this.insertSpace();
      }
    },
    onSectorOutsideClick () {
      this.isSectorOpen = false;
    },
    onSectorDetailOutSideClick () {
      this.isSectorDetailOpen = false;
    },
    onDeleteSpace (msg) {
      this.isPopUpModal = true;
      this.popupModalText = msg;
    },
    checkRegistCard () {
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/product/getMyCardInfo', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            result != null ? (this.isRegistCard = true) : (this.isRegistCard = false);
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    getLatLng () {
      var kakao = window.kakao;
      var geocoder = new kakao.maps.services.Geocoder();

      geocoder.addressSearch(this.spaceInfo.spaceAddr, (result, status) => {
        if (status === kakao.maps.services.Status.OK) {
          this.spaceInfo.spaceLongitude = result[0].x;
          this.spaceInfo.spaceLatitude = result[0].y;
        }
      });
    },
    closeModal () {
      this.checkSpaceNm = false;
      this.spaceInfo.spaceNm = '';
      this.spaceName = '';
      if (this.inputStatus === 'map') {
        this.spaceInfo.spaceAddr = '';
        this.spaceInfo.spaceAddrDetail = '';
      }
      document.getElementById('search').focus();
    },
    onCloseModal () {
      this.isPopUpModal = false;
      this.$router.push({ name: 'MySpace' });
    },
    closeFranchise () {
      this.franchiseStatus = false;
      this.searchBrandNm = '';
    },
    closeMap () {
      this.isMap = false;
      this.spaceInfo.spaceNm = '';
    },
    insertBrand (item) {
      this.franchiseStatus = false;
      this.searchBrandNm = item.brandNm;
      this.spaceInfo.brandId = item.brandId;
    },
    searchBrand () {
      this.franchiseStatus = true;
    },
    insertCheckSpaceNm () {
      var spaceNm = this.spaceInfo.spaceNm;
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/checkSpaceNm', { spaceNm }, { headers })
        .then(res => {
          // console.log(res.data);
          if (res.data.resultCd === '0000') {
            if (res.data.result === 0) {
              this.insertSpace();
            } else {
              this.checkSpaceNm = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateCheckSpaceNm () {
      var spaceNm = this.spaceInfo.spaceNm;
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/checkSpaceNm', { spaceNm }, { headers })
        .then(res => {
          // console.log(res.data);
          if (res.data.resultCd === '0000') {
            if (res.data.result === 0) {
              this.updateSpaceInfo();
            } else {
              this.checkSpaceNm = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleError (err) {
      if (err.response) {
        const { data } = err.response;
        const { resultCd, resultMsg } = data;
        if (resultCd === '400' && resultMsg === '삭제된 스페이스입니다.') {
          this.isPopUpModal = true;
          this.popupModalText = resultMsg;
          return false;
        }
      }
    },
    insertSpace () {
      this.disabledInsert = true;
      var spaceType = 'Store';
      var userId = VueCookies.get('userId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/createSpaceInfo', { userId, spaceType }, { headers })
        .then(res => {
          var spaceId = res.data.result;
          localStorage.setItem('spaceId', spaceId);
          this.spaceInfo.spaceId = spaceId;

          axios
            .post('/api/profile/createCurationInfo', { spaceId }, { headers })
            .then(response => {
              axios
                .post('/api/profile/createPreferMusicList', { spaceId }, { headers })
                .then(res1 => {
                  var spaceInfo = this.spaceInfo;
                  axios
                    .post('/api/profile/updateSpaceInfo', spaceInfo, { headers })
                    .then(res2 => {
                      if (res2.data.resultCd === '0000') {
                        this.disabledInsert = false;
                        this.$router.push({ name: 'SpaceInsertStoreColor' }).catch(() => {});
                      }
                    })
                    .catch(err2 => this.handleError(err2, 'insertSpace'));
                })
                .catch(err1 => {
                  this.disabledInsert = false;
                  console.log(err1);
                });
            })
            .catch(error => {
              this.disabledInsert = false;
              console.log(error);
            });
        })
        .catch(err => {
          this.disabledInsert = false;
          console.log(err);
        });
    },
    updateSpaceInfo () {
      // 매장 정보 수정
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };
      var spaceInfo = this.spaceInfo;
      VueCookies.remove('spaceNm'); // 쿠키 스페이스명 삭제

      axios
        .post('/api/profile/updateSpaceInfo', spaceInfo, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.$router.push({ name: 'SpaceInsertStoreColor' }).catch(() => {});
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    async getSpaceInfo () {
      // 등록된 매장정보 가져오기
      var spaceId = localStorage.getItem('spaceId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      await axios
        .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
        .then(res => {
          this.spaceInfo = res.data.result;

          if (this.spaceInfo.brandId != null && this.spaceInfo.brandId !== '') {
            this.getBrandInfo();
          }
          this.getSectors();
        })
        .catch(err => {
          console.log(err);
        });
    },
    getBrandInfo () {
      var brandId = this.spaceInfo.brandId;
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/getBrand', { brandId }, { headers })
        .then(res => {
          // console.log(res.data.result);
          this.searchBrandNm = res.data.result.brandNm;
        })
        .catch(err => {
          console.log(err);
        });
    },
    linkMySpace () {
      var spaceId = this.spaceId;
      if (spaceId == null) {
        this.cancelCheckStatus = true;
      } else {
        var aToken = VueCookies.get('aToken');
        var headers = { 'X-AUTH-TOKEN': aToken };

        axios
          .post('/api/profile/checkSubscribe', { spaceId }, { headers })
          .then(res => {
            if (res.data.resultCd === '0000') {
              if (res.data.result === 'S') {
                this.cancelCheckStatus = true;
              } else {
                router.push({ name: 'Player' });
              }
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    clickPre () {
      history.back();
    },
    setSpaceInfo (spaceInfo) {
      // 매장검색을 통해 매장명, 주소 값 받기
      this.spaceInfo.spaceNm = spaceInfo.spaceNm;
      this.spaceInfo.spaceAddr = spaceInfo.spaceAddr;
      this.spaceInfo.spaceLatitude = spaceInfo.y;
      this.spaceInfo.spaceLongitude = spaceInfo.x;
      this.spaceInfo.spaceAddrDetail = '';
    },
    showMap () {
      // 매장검색 모달 열기
      this.spaceName = this.spaceInfo.spaceNm;
      this.isMap = true;
    },
    searchAddrVal (select) {
      this.inputStatus = select;
      if (select === 'map') {
        this.showMap();
      }

      this.spaceInfo.spaceAddr = '';
      this.spaceInfo.spaceAddrDetail = '';
    },
    searchAddress () {
      // 주소검색
      new window.daum.Postcode({
        oncomplete: data => {
          var addr = '';

          // 사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          if (data.userSelectedType === 'R') {
            // 사용자가 도로명 주소를 선택했을 경우
            addr = data.roadAddress;
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            addr = data.jibunAddress;
          }
          // 주소 정보를 해당 필드에 넣는다.
          this.spaceInfo.spaceAddrDetail = '';
          this.spaceInfo.spaceAddr = addr;
          // 커서를 상세주소 필드로 이동한다.
          document.getElementById('addressDetail').focus();

          setTimeout(() => {
            this.getLatLng();
          }, 1000);
        }
      }).open();
    },
    setSector (item) {
      const { sectorNm } = item;
      this.spaceInfo.sector = sectorNm;
      this.spaceInfo.sectorDetail = '소분류';
      this.setSectorDetail(item);
      if (this.isMoSectorOpen) {
        this.isMoSectorOpen = false;
      }
    },
    async setSectorDetail (item) {
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      const { sectorId } = item;
      axios
        .post('/api/profile/getSectorDetailList', { sectorId }, { headers })
        .then(res => {
          this.sectorDetails = res.data.result;
        })
        .catch(err => {
          console.log(err);
        });
    },
    setSectorDetailVal (val) {
      const { sectorDetailNm } = val;
      this.spaceInfo.sectorDetail = sectorDetailNm;
      if (this.isMoSectorDetailOpen) {
        this.isMoSectorDetailOpen = false;
      }
    },
    async getSectors () {
      // 업종 목록 가져오기
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      await axios
        .post('/api/profile/getSectorList', null, { headers })
        .then(res => {
          // console.log(res.data.result);
          this.sectors = res.data.result;

          var detailNm = this.spaceInfo.sector;
          for (var i in this.sectors) {
            if (this.sectors[i].sectorNm) {
              var detailId = this.sectors[i].sectorNm.indexOf(detailNm);
              if (detailId === 0) {
                this.detailInfo = this.sectors[i];
                this.getSectorDetails(this.detailInfo.sectorId);
              }
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getSectorDetails (item) {
      // 업종상세 목록 가져오기
      var sectorId = item;
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/getSectorDetailList', { sectorId }, { headers })
        .then(res => {
          // console.log(res.data.result);
          this.sectorDetails = res.data.result;
        })
        .catch(err => {
          console.log(err);
        });
    },
    dateTime (value) {
      return dayjs(value).format('HH:mm');
    },
    selectStartTime () {
      this.startStatus = true;
    },
    selectEndTime () {
      this.endStatus = true;
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  async mounted () {
    this.checkRegistCard();
    window.scrollTo(0, 0);
    setTimeout(() => {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf('aplayzaos') !== -1) {
        document.getElementsByClassName('mo_space_header')[0].style.padding = '12px 0 0';
      } else if (userAgent.indexOf('aplayznativeaos') !== -1) {
        document.getElementsByClassName('mo_space_header')[0].style.padding = '12px 0 0';
      }
    }, 1000);
    if (this.prevRoute && this.prevRoute.path === '/space/insertStoreColor') {
      this.spaceId = localStorage.getItem('spaceId');
      await this.getSpaceInfo();
    } else {
      if (this.spaceId === localStorage.getItem('spaceId') && localStorage.getItem('spaceId') !== null) {
        await this.getSpaceInfo();
      } else {
        // 매장추가
        await this.getSectors();
        this.spaceInfo.sectorDetail = null;
      }
    }
  },
  computed: {
    btnDisabled () {
      if (
        this.spaceInfo.spaceNm &&
        this.spaceInfo.spaceAddr &&
        this.spaceInfo.sector &&
        this.spaceInfo.sectorDetail !== '소분류'
      ) {
        return false;
      }

      return true;
    }
  }
});
</script>

<style scoped>
.disabled {
  pointer-events: none;
}

.modal {
  display: block;
}

.myspace_content_form .input_btn p.on {
  background-color: white;
  color: black;
}

.myspace_content_form .input_btn p.on:hover {
  opacity: 0.8;
}

.myspace_content_form .search-addr {
  position: absolute;
  top: 25%;
  right: 2px;
  transform: translateY(-60%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.myspace_content_form .search-addr {
  padding: 8px 16px;
  background: transparent;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
}

.myspace_content_form .search-addr {
  margin-right: 7px;
  background: #fff;
  color: #151515;
}

.myspace_content_form .search-addr:hover {
  background: rgba(255, 255, 255, 0.8);
}

.input_btn2 {
  position: absolute;
  top: 25%;
  right: 10px;
  transform: translateY(-60%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.input_btn2 {
  padding: 8px 12px;
  background: white;
  color: black;
  border-radius: 8px;
}

@media all and (max-width: 500px) {
  .select-box-dropDown ul {
    padding: 0;
  }

  .space_down_box1 ul li {
    margin-bottom: 12px;
  }

  .space_down_box2 ul li {
    margin-bottom: 12px;
  }

  .select_box {
    margin-bottom: 24px;
  }
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
<style src="@/assets/css/insertspace.css" scoped></style>
