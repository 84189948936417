import { render, staticRenderFns } from "./SpaceInsertStore.vue?vue&type=template&id=51b84afa&scoped=true"
import script from "./SpaceInsertStore.vue?vue&type=script&lang=js"
export * from "./SpaceInsertStore.vue?vue&type=script&lang=js"
import style0 from "./SpaceInsertStore.vue?vue&type=style&index=0&id=51b84afa&prod&scoped=true&lang=css"
import style1 from "@/assets/css/content2.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "@/assets/css/font.css?vue&type=style&index=2&prod&lang=css&external"
import style3 from "@/assets/css/insertspace.css?vue&type=style&index=3&id=51b84afa&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51b84afa",
  null
  
)

export default component.exports